import { call, put, takeLatest } from 'redux-saga/effects';
import { getUserAddresses } from '../../api/Services/Addresses';
import {
	setAddresses,
	setAddressesLoading,
	setAddressesError,
} from '../slices/addressesSlice';

function* fetchUserAddresses(action) {
	yield put(setAddressesLoading(true));
	try {
		const response = yield call(() =>
			getUserAddresses(action.payload.userNanoId),
		);
		yield put(setAddresses(response));
		yield put(setAddressesLoading(false));
	} catch (err) {
		yield put(setAddressesError(err.response.data));
		yield put(setAddressesLoading(false));
	}
}

export function* addressesModuleSaga() {
	yield takeLatest('addresses/fetchUserAddresses', fetchUserAddresses);
}
