import { object, string } from 'yup';
import { ERROR_MESSAGES } from '../../utils/error-messages';
const { required } = ERROR_MESSAGES;

export const availableLanguages = [
	{ id: 'tr', name: 'Turkish' },
	{ id: 'en', name: 'English' },
	{ id: 'af', name: 'Afrikaans' },
	{ id: 'sq', name: 'Albanian' },
	{ id: 'am', name: 'Amharic' },
	{ id: 'ar', name: 'Arabic' },
	{ id: 'hy', name: 'Armenian' },
	{ id: 'az', name: 'Azerbaijani' },
	{ id: 'bn', name: 'Bengali' },
	{ id: 'bs', name: 'Bosnian' },
	{ id: 'bg', name: 'Bulgarian' },
	{ id: 'ca', name: 'Catalan' },
	{ id: 'zh', name: 'Chinese (Simplified)' },
	{ id: 'zh-TW', name: 'Chinese (Traditional)' },
	{ id: 'hr', name: 'Croatian' },
	{ id: 'cs', name: 'Czech' },
	{ id: 'da', name: 'Danish' },
	{ id: 'fa-AF', name: 'Dari' },
	{ id: 'nl', name: 'Dutch' },
	{ id: 'et', name: 'Estonian' },
	{ id: 'fa', name: 'Farsi (Persian)' },
	{ id: 'tl', name: 'Filipino, Tagalog' },
	{ id: 'fi', name: 'Finnish' },
	{ id: 'fr', name: 'French' },
	{ id: 'fr-CA', name: 'French (Canada)' },
	{ id: 'ka', name: 'Georgian' },
	{ id: 'de', name: 'German' },
	{ id: 'el', name: 'Greek' },
	{ id: 'gu', name: 'Gujarati' },
	{ id: 'ht', name: 'Haitian Creole' },
	{ id: 'ha', name: 'Hausa' },
	{ id: 'he', name: 'Hebrew' },
	{ id: 'hi', name: 'Hindi' },
	{ id: 'hu', name: 'Hungarian' },
	{ id: 'is', name: 'Icelandic' },
	{ id: 'id', name: 'Indonesian' },
	{ id: 'ga', name: 'Irish' },
	{ id: 'it', name: 'Italian' },
	{ id: 'ja', name: 'Japanese' },
	{ id: 'kn', name: 'Kannada' },
	{ id: 'kk', name: 'Kazakh' },
	{ id: 'ko', name: 'Korean' },
	{ id: 'lv', name: 'Latvian' },
	{ id: 'lt', name: 'Lithuanian' },
	{ id: 'mk', name: 'Macedonian' },
	{ id: 'ms', name: 'Malay' },
	{ id: 'ml', name: 'Malayalam' },
	{ id: 'mt', name: 'Maltese' },
	{ id: 'mr', name: 'Marathi' },
	{ id: 'mn', name: 'Mongolian' },
	{ id: 'no', name: 'Norwegian (Bokmål)' },
	{ id: 'ps', name: 'Pashto' },
	{ id: 'pl', name: 'Polish' },
	{ id: 'pt', name: 'Portuguese (Brazil)' },
	{ id: 'pt-PT', name: 'Portuguese (Portugal)' },
	{ id: 'pa', name: 'Punjabi' },
	{ id: 'ro', name: 'Romanian' },
	{ id: 'ru', name: 'Russian' },
	{ id: 'sr', name: 'Serbian' },
	{ id: 'si', name: 'Sinhala' },
	{ id: 'sk', name: 'Slovak' },
	{ id: 'sl', name: 'Slovenian' },
	{ id: 'so', name: 'Somali' },
	{ id: 'es', name: 'Spanish' },
	{ id: 'es-MX', name: 'Spanish (Mexico)' },
	{ id: 'sw', name: 'Swahili' },
	{ id: 'sv', name: 'Swedish' },
	{ id: 'ta', name: 'Tamil' },
	{ id: 'te', name: 'Telugu' },
	{ id: 'th', name: 'Thai' },
	{ id: 'uk', name: 'Ukrainian' },
	{ id: 'ur', name: 'Urdu' },
	{ id: 'uz', name: 'Uzbek' },
	{ id: 'vi', name: 'Vietnamese' },
	{ id: 'cy', name: 'Welsh' },
];

export const updateMarketingDetailsSchema = object({
	title: string().nullable().min(3, 'Too Short').required(required('title')),
	description: string()
		.nullable()
		.min(3, 'Too Short')
		.required(required('description')),
});

export const genderNames = {
	'I7-kwzEwH3bTz47m': 'Kadın',
	zZSmdQInlGDNPWb0: 'Erkek',
	S32ZAuvGFK48BLLR: 'Belirtmek istemiyor',
};

export const segmentSesNames = {
	cyB61t9BfZ7I8yL3: 'A',
	LoqQY6Q8iH06rFQ9: 'B',
	eBPYffexR4VQHLoR: 'C1',
	'xO-J9UJY-BSybxlx': 'C2',
	kwxl26VE2sTsP2lH: 'D',
	'tXW1QTAmW20j-8LG': 'E',
};

export const educationNames = {
	'vGKjx9ArFOOYtc-S': 'İlkokul terk',
	'8xrhtARGhZ1sdvu0': 'İlkokul mezunu',
	'Zw6x5Lgda3W0yq-A': 'Ortaokul mezunu',
	'6b3pVnZmcUZdGuwQ': 'Lise mezunu',
	RC5JN1ffr6qeO9ao: 'Yüksekokul mezunu',
	i94Gu55nLIWvh1pE: 'Açıköğretim mezunu',
	'WdMPMcstVzRT2La-': 'Üniversite mezunu',
	lNNmHT5yJSJoRTZG: 'Master, doktora ve üzeri',
};

export const ageNames = {
	k2bxkf9rsnqEcPaR: '16-20',
	'J0L-MpnafHRlSBNJ': '21-24',
	YFIvuFCxn7WjSO48: '25-29',
	tF4n2GHOmM6ySoiR: '30-34',
	aESGAh7zkx8jXVrF: '35-39',
	'5mKYpO4aiVxOCng6': '40-44',
	'4sl4NcYfZoc-7cMn': '45-49',
	JUZsdq5yj1ZJInEK: '50-54',
	pwgpNQJP10Ljdu0E: '55+',
};

export const regionNames = {
	AJoecDeeqphDRGVs: 'Akdeniz',
	dZmTTu0maMndXApQ: 'Doğu Anadolu',
	KoUblWl4tpTGqbJm: 'Ege',
	IHYJeUsk2985swQ4: 'Güneydoğu Anadolu',
	'8dCKa9IsxWSFLFF8': 'İç Anadolu',
	Jw4Ln10mpdSsimwT: 'Karadeniz',
	'5A2PQMXkBXSjrnCx': 'Marmara',
};

export const availableCountries = [
	{ id: 'tr', name: 'Turkey', language: 'Turkish' },
	{ id: 'gb', name: 'United Kingdom', language: 'English' },
];
