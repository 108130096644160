import { Formik, Form, Field } from 'formik';
import { FormInput } from '../../components/TextInput';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOrder } from '../../api/Services/Checkout';
import { Dialog, Transition } from '@headlessui/react';
import {
	getProjectPurchaseOrders,
	getProjectCost,
	deleteTransaction,
} from '../../api/Services/Projects';

export default function BillingTab({ project }) {
	const dispatch = useDispatch();
	const { addresses, loading: isLoading } = useSelector(
		state => state.addresses,
	);
	const [selectedAddress, setSelectedAddress] = useState('');
	const [formStatus, setFormStatus] = useState({ type: '', message: '' });
	const [purchaseOrders, setPurchaseOrders] = useState([]);
	const [isLoadingPOs, setIsLoadingPOs] = useState(false);
	const [projectAmount, setProjectAmount] = useState(0);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedPO, setSelectedPO] = useState(null);
	const formikRef = useRef(null);

	const isDisabled = project?.payment_mode !== 'payg';

	useEffect(() => {
		if (project?.user?.nanoid) {
			dispatch({
				type: 'addresses/fetchUserAddresses',
				payload: { userNanoId: project.user.nanoid },
			});
		}
	}, [dispatch, project?.user?.nanoid]);

	useEffect(() => {
		if (addresses?.length > 0) {
			setSelectedAddress(addresses[0].nanoid);
		}
	}, [addresses]);

	useEffect(() => {
		const fetchProjectCost = async () => {
			if (project?.nanoid) {
				try {
					const data = await getProjectCost(project.nanoid);
					setProjectAmount(Number(data.cost?.raw_value) || 0);
				} catch (error) {
					// Error handled silently
				}
			}
		};

		fetchProjectCost();
	}, [project?.nanoid]);

	useEffect(() => {
		const fetchPurchaseOrders = async () => {
			if (project?.nanoid) {
				setIsLoadingPOs(true);
				try {
					const data = await getProjectPurchaseOrders(project.nanoid);
					setPurchaseOrders(data);
				} catch (error) {
					// Error handled silently
				} finally {
					setIsLoadingPOs(false);
				}
			}
		};

		fetchPurchaseOrders();
	}, [project?.nanoid]);

	const totalPOAmount = purchaseOrders.reduce(
		(sum, po) => sum + (Number(po?.amount?.raw_value) || 0),
		0,
	);
	const isAmountMatch = totalPOAmount === projectAmount;
	const currencySymbol = project?.country_code === 'TR' ? '₺' : '£';

	const handleSubmit = async values => {
		try {
			setFormStatus({
				type: 'loading',
				message: 'Creating purchase order...',
			});

			const orderData = {
				address: values.address,
				project: project.nanoid,
				payment_type: 'purchase_order',
				purchase_order: values.purchaseOrder,
				purchase_amount: parseFloat(values.amount),
			};

			await createOrder(orderData);
			setFormStatus({
				type: 'success',
				message: 'Purchase order created successfully!',
			});
			formikRef.current?.resetForm();

			// Refresh PO list and project cost immediately
			const [poData, costData] = await Promise.all([
				getProjectPurchaseOrders(project.nanoid),
				getProjectCost(project.nanoid),
			]);
			setPurchaseOrders(poData);
			setProjectAmount(Number(costData.cost?.raw_value) || 0);
		} catch (error) {
			if (error.response?.data?.code === 'questionnaire_100_4') {
				setFormStatus({
					type: 'error',
					message:
						'Questionnaire is not valid. ' +
						'Please fix the questionnaire errors and try again.',
				});
			} else if (error.response?.data?.errors) {
				const errorMessages = Object.entries(error.response.data.errors)
					.map(([field, messages]) => {
						const fieldName =
							field === 'purchase_order'
								? 'Purchase Order Number'
								: field === 'purchase_amount'
								? 'Purchase Amount'
								: field;
						const translatedMessages = messages.map(msg => {
							if (msg === 'Bu alan boş bırakılmamalı.') {
								return 'This field is required.';
							}
							return msg;
						});
						return `${fieldName}:\n${translatedMessages.join('\n')}`;
					})
					.join('\n\n');
				setFormStatus({
					type: 'error',
					message: errorMessages,
				});
			} else {
				setFormStatus({
					type: 'error',
					message: 'An error occurred while creating the order.',
				});
			}
		}
	};

	const handleDeletePO = async id => {
		setSelectedPO(id);
		setIsDeleteModalOpen(true);
	};

	const confirmDelete = async () => {
		try {
			await deleteTransaction(selectedPO);
			// Refresh PO list
			const data = await getProjectPurchaseOrders(project.nanoid);
			setPurchaseOrders(data);
			// Refresh project cost
			const costData = await getProjectCost(project.nanoid);
			setProjectAmount(Number(costData.cost?.raw_value) || 0);
		} catch (error) {
			// Error handled silently
		} finally {
			setIsDeleteModalOpen(false);
			setSelectedPO(null);
		}
	};

	return (
		<>
			<div className="flex gap-6">
				<section aria-labelledby="billing-title" className="max-w-2xl">
					<div
						className={`bg-white shadow sm:rounded-lg ${
							isDisabled ? 'opacity-50 pointer-events-none' : ''
						}`}
					>
						<div className="px-4 py-5 sm:px-6">
							<h2
								id="billing-title"
								className="text-lg font-medium leading-6 text-gray-900"
							>
								Create Purchase Order
							</h2>
							<p className="mt-1 max-w-2xl text-sm text-gray-500">
								{isDisabled
									? 'Purchase orders are only available for pay-as-you-go projects.'
									: 'Create and process payment for the project purchase order.'}
							</p>
						</div>
						<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
							<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-black">Project ID</dt>
									<dd className="mt-1 text-sm text-gray-900">
										{project.nanoid}
									</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-black">
										Project Status
									</dt>
									<dd className="mt-1 text-sm text-gray-900 capitalize">
										{project.status}
									</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-black">Client</dt>
									<dd className="mt-1 text-sm text-gray-900">
										{project.user?.first_name} {project.user?.last_name}
									</dd>
								</div>
							</dl>

							<Formik
								initialValues={{
									address: selectedAddress,
									purchaseOrder: '',
									amount: '',
								}}
								enableReinitialize
								onSubmit={handleSubmit}
								ref={formikRef}
							>
								{({ isSubmitting, resetForm }) => (
									<Form className="mt-8 space-y-6">
										{formStatus.message && (
											<div
												className={`rounded-md p-4 ${
													formStatus.type === 'success'
														? 'bg-green-50 text-green-700'
														: 'bg-red-50 text-red-700'
												}`}
											>
												<p className="text-sm font-medium whitespace-pre-line">
													{formStatus.message}
												</p>
											</div>
										)}

										<div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
											<div className="sm:col-span-2">
												<label
													htmlFor="address"
													className="block text-sm font-medium text-gray-700"
												>
													Billing Address
												</label>
												<Field
													as="select"
													id="address"
													name="address"
													className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
													disabled={isLoading}
												>
													{addresses?.length > 0 ? (
														addresses.map(address => (
															<option
																key={address.nanoid}
																value={address.nanoid}
															>
																{address.title} - {address.line}
															</option>
														))
													) : (
														<option value="">No address found</option>
													)}
												</Field>
											</div>

											<div>
												<label
													htmlFor="purchaseOrder"
													className="block text-sm font-medium text-gray-700"
												>
													Purchase Order Number
												</label>
												<FormInput
													type="text"
													name="purchaseOrder"
													id="purchaseOrder"
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
												/>
											</div>

											<div>
												<label
													htmlFor="amount"
													className="block text-sm font-medium text-gray-700"
												>
													Purchase Amount
												</label>
												<div className="relative mt-1 rounded-md shadow-sm">
													<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
														<span className="text-gray-500 sm:text-sm">
															{currencySymbol}
														</span>
													</div>
													<FormInput
														type="number"
														name="amount"
														id="amount"
														className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
														placeholder="0.00"
														step="0.01"
													/>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
														<span className="text-gray-500 sm:text-sm">
															{project?.country_code === 'TR' ? 'TL' : 'GBP'}
														</span>
													</div>
												</div>
											</div>
										</div>

										<div className="flex justify-end space-x-3">
											<button
												type="button"
												onClick={() => {
													resetForm();
													setFormStatus({ type: '', message: '' });
												}}
												className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
											>
												Reset
											</button>
											<button
												type="submit"
												disabled={isSubmitting}
												className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
											>
												Create Purchase Order
											</button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</section>

				<section aria-labelledby="po-list-title" className="flex-1">
					<div
						className={`bg-white shadow sm:rounded-lg ${
							isDisabled ? 'opacity-50 pointer-events-none' : ''
						}`}
					>
						<div className="px-4 py-5 sm:px-6">
							<div className="flex items-center justify-between">
								<div>
									<h2
										id="po-list-title"
										className="text-lg font-medium leading-6 text-gray-900"
									>
										Purchase Orders
									</h2>
									<p className="mt-1 max-w-2xl text-sm text-gray-500">
										{isDisabled
											? 'Purchase orders are only available for pay-as-you-go projects.'
											: 'List of all purchase orders for this project.'}
									</p>
								</div>
								<div className="text-right">
									<p className="text-sm font-medium text-gray-900">
										Project Amount: {currencySymbol}
										{projectAmount.toLocaleString()}
									</p>
									<p className="text-sm font-medium text-gray-900">
										Total PO Amount: {currencySymbol}
										{totalPOAmount.toLocaleString()}
									</p>
									<p
										className={`text-sm font-medium ${
											isAmountMatch ? 'text-green-600' : 'text-red-600'
										}`}
									>
										{isAmountMatch
											? 'Amounts match ✓'
											: `Amount mismatch: ${currencySymbol}${Math.abs(
													projectAmount - totalPOAmount,
											  ).toLocaleString()}`}
									</p>
								</div>
							</div>
						</div>
						<div className="border-t border-gray-200">
							<ul role="list" className="divide-y divide-gray-200">
								{isLoadingPOs ? (
									<li className="py-4 px-4 sm:px-6 text-center text-gray-500">
										Loading purchase orders...
									</li>
								) : purchaseOrders.length > 0 ? (
									purchaseOrders.map(po => (
										<li
											key={po?.nanoid}
											className="flex items-center justify-between py-4 px-4 sm:px-6"
										>
											<div className="flex items-center space-x-4">
												<div>
													<p className="text-sm font-medium text-gray-900">
														{po?.transaction_id}
													</p>
													<p className="text-sm text-gray-500">
														{currencySymbol}
														{Number(
															po?.amount?.raw_value || 0,
														).toLocaleString()}
													</p>
												</div>
												<div className="text-sm text-gray-500">
													{moment(po?.created_at).format('DD MMM YYYY')}
												</div>
											</div>
											<button
												type="button"
												onClick={() => handleDeletePO(po?.nanoid)}
												className="ml-4 text-gray-400 hover:text-red-500"
											>
												<TrashIcon className="h-5 w-5" />
											</button>
										</li>
									))
								) : (
									<li className="py-4 px-4 sm:px-6 text-center text-gray-500">
										No purchase orders found
									</li>
								)}
							</ul>
						</div>
					</div>
				</section>
			</div>

			<Transition.Root show={isDeleteModalOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={setIsDeleteModalOpen}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationTriangleIcon
												className="h-6 w-6 text-red-600"
												aria-hidden="true"
											/>
										</div>
										<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
											<Dialog.Title
												as="h3"
												className="text-base font-semibold leading-6 text-gray-900"
											>
												Delete Purchase Order
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													Are you sure you want to delete this purchase order?
													This action cannot be undone.
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
											onClick={confirmDelete}
										>
											Delete
										</button>
										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
											onClick={() => setIsDeleteModalOpen(false)}
										>
											Cancel
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
