import React, { useState } from 'react';
import {
	GlobeAltIcon,
	XMarkIcon,
	CheckIcon,
	ChevronUpDownIcon,
} from '@heroicons/react/24/outline';
import { Dialog, Listbox, Transition } from '@headlessui/react';
import { availableLanguages, availableCountries } from './constant';
import { sorbunuApi } from '../../api/axios';
import { endPoints } from '../../endPoints';

export default function LanguagesTab({ project }) {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState(null);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [languageToDelete, setLanguageToDelete] = useState(null);
	const [languages, setLanguages] = useState(project?.languages || []);

	const isApproved = project.status == 'completed';

	const handleAddLanguage = async () => {
		if (!selectedLanguage) return;
		if (!project?.questionnaire) {
			setErrorMessage('Questionnaire not found.');
			setShowErrorMessage(true);
			return;
		}

		setIsLoading(true);
		try {
			try {
				// Add language
				await sorbunuApi.post(
					endPoints.questionnaires.ADD_LANGUAGE(project.questionnaire),
					{
						language: selectedLanguage,
					},
				);
			} catch (error) {
				// If language already exists, continue with translation
				if (error.response?.data?.errors?.code !== 'language-already-exists') {
					throw error;
				}
			}

			// Trigger translation
			await sorbunuApi.post(
				endPoints.questionnaires.TRANSLATE_PROJECT(project.questionnaire),
			);

			// Add new language to the list
			setLanguages(prev => [...prev, { language: selectedLanguage }]);
			setIsOpen(false);
			setSelectedLanguage(null);
		} catch (error) {
			setErrorMessage('Failed to add language. Please try again.');
			setShowErrorMessage(true);
		} finally {
			setIsLoading(false);
		}
	};

	const handleDeleteClick = (languageId, languageName) => {
		setLanguageToDelete({ id: languageId, name: languageName });
		setShowDeleteConfirmation(true);
	};

	const handleDeleteLanguage = async () => {
		if (!project?.questionnaire) {
			setErrorMessage('Questionnaire not found.');
			setShowErrorMessage(true);
			return;
		}

		setIsLoading(true);
		try {
			// Delete language
			await sorbunuApi.delete(
				endPoints.questionnaires.REMOVE_LANGUAGE(
					project.questionnaire,
					languageToDelete.id,
				),
			);

			// Remove language from the list
			setLanguages(prev =>
				prev.filter(lang => lang.language !== languageToDelete.id),
			);
		} catch (error) {
			setErrorMessage('Failed to remove language. Please try again.');
			setShowErrorMessage(true);
		} finally {
			setIsLoading(false);
			setShowDeleteConfirmation(false);
			setLanguageToDelete(null);
		}
	};

	return (
		<div className="grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
			<div className="space-y-6 lg:col-span-2 lg:col-start-1">
				<section aria-labelledby="languages-title">
					<div className="bg-white shadow sm:rounded-lg">
						<div className="px-4 py-5 sm:px-6">
							<div className="flex items-center justify-between">
								<div>
									<h2
										id="languages-title"
										className="text-lg font-medium leading-6 text-gray-900"
									>
										Project Languages
									</h2>
									<p className="mt-1 max-w-2xl text-sm text-gray-500">
										Manage languages for {project.title}
									</p>
								</div>
								<button
									type="button"
									onClick={() => setIsOpen(true)}
									disabled={!isApproved}
									className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
										isApproved
											? 'bg-indigo-600 hover:bg-indigo-500'
											: 'bg-gray-400 cursor-not-allowed'
									}`}
								>
									Add Languages
								</button>
							</div>
						</div>
						<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
							<div className="space-y-4">
								<div className="mt-5">
									<div className="flex flex-wrap gap-2">
										{(() => {
											const defaultLanguage = project?.country_code
												? availableCountries.find(
														country =>
															country.id === project.country_code.toLowerCase(),
												  )?.language
												: null;

											return defaultLanguage ? (
												<span className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-50 px-3 py-2 text-sm font-medium text-indigo-700">
													<GlobeAltIcon className="h-5 w-5" />
													{defaultLanguage}
												</span>
											) : null;
										})()}

										{languages.map(item => {
											const language = availableLanguages.find(
												lang => lang.id === item.language,
											);
											return (
												<span
													key={item.language}
													className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-50 px-3 py-2 text-sm font-medium text-blue-700"
												>
													<GlobeAltIcon className="h-5 w-5" />
													{language?.name}
													{isApproved && (
														<button
															onClick={() =>
																handleDeleteClick(item.language, language?.name)
															}
															disabled={isLoading}
															className={`ml-1 ${
																isLoading
																	? 'text-gray-400 cursor-not-allowed'
																	: 'text-blue-600 hover:text-blue-900'
															}`}
														>
															<XMarkIcon className="h-4 w-4" />
														</button>
													)}
												</span>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<Dialog
				open={isOpen}
				onClose={() => setIsOpen(false)}
				className="relative z-50"
			>
				<div className="fixed inset-0 bg-black/30" aria-hidden="true" />

				<div className="fixed inset-0 flex items-center justify-center">
					<Dialog.Panel className="mx-auto w-full max-w-2xl rounded-lg bg-white shadow-xl">
						<div className="px-6 py-5 border-b border-gray-200">
							<div className="flex items-center justify-between">
								<Dialog.Title className="text-xl font-semibold leading-6 text-gray-900">
									Add Languages
								</Dialog.Title>
								<button
									type="button"
									onClick={() => setIsOpen(false)}
									className="rounded-md text-gray-400 hover:text-gray-500"
								>
									<XMarkIcon className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
						</div>

						<div className="px-6 py-6">
							<Listbox value={selectedLanguage} onChange={setSelectedLanguage}>
								<div className="relative">
									<Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
										<span className="block truncate">
											{selectedLanguage
												? availableLanguages.find(
														lang => lang.id === selectedLanguage,
												  ).name
												: 'Select a language'}
										</span>
										<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
											<ChevronUpDownIcon
												className="h-5 w-5 text-gray-400"
												aria-hidden="true"
											/>
										</span>
									</Listbox.Button>
									<Transition
										as={React.Fragment}
										leave="transition ease-in duration-100"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
											{availableLanguages.map(language => (
												<Listbox.Option
													key={language.id}
													className={({ active }) =>
														`relative cursor-default select-none py-2 pl-10 pr-4 ${
															active
																? 'bg-indigo-600 text-white'
																: 'text-gray-900'
														}`
													}
													value={language.id}
												>
													{({ selected, active }) => (
														<>
															<span
																className={`block truncate ${
																	selected ? 'font-medium' : 'font-normal'
																}`}
															>
																{language.name}
															</span>
															{selected ? (
																<span
																	className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
																		active ? 'text-white' : 'text-indigo-600'
																	}`}
																>
																	<CheckIcon
																		className="h-5 w-5"
																		aria-hidden="true"
																	/>
																</span>
															) : null}
														</>
													)}
												</Listbox.Option>
											))}
										</Listbox.Options>
									</Transition>
								</div>
							</Listbox>
						</div>

						<div className="px-6 py-5 bg-gray-50 rounded-b-lg">
							<button
								type="button"
								onClick={handleAddLanguage}
								disabled={!selectedLanguage || isLoading}
								className={`w-full rounded-md px-4 py-3 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
									!selectedLanguage || isLoading
										? 'bg-gray-400 cursor-not-allowed'
										: 'bg-indigo-600 hover:bg-indigo-500'
								}`}
							>
								{isLoading ? 'Adding Language...' : 'Add Language'}
							</button>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>

			<Dialog
				open={showSuccessMessage}
				onClose={() => setShowSuccessMessage(false)}
				className="relative z-50"
			>
				<div className="fixed inset-0 bg-black/30" aria-hidden="true" />

				<div className="fixed inset-0 flex items-center justify-center">
					<Dialog.Panel className="mx-auto max-w-md rounded-lg bg-white p-6 shadow-xl">
						<div className="text-center">
							<Dialog.Title className="text-lg font-medium leading-6 text-gray-900 mb-4">
								Language is being added
							</Dialog.Title>
							<p className="text-sm text-gray-500 mb-6">
								The selected language will be auto-translated. This might take a
								few minutes depending on the size of the project. You should
								receive a confirmation email once this operation is done.
							</p>
							<button
								type="button"
								onClick={() => setShowSuccessMessage(false)}
								className="inline-flex justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
							>
								Okay
							</button>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>

			<Dialog
				open={showErrorMessage}
				onClose={() => setShowErrorMessage(false)}
				className="relative z-50"
			>
				<div className="fixed inset-0 bg-black/30" aria-hidden="true" />

				<div className="fixed inset-0 flex items-center justify-center">
					<Dialog.Panel className="mx-auto max-w-md rounded-lg bg-white p-6 shadow-xl">
						<div className="text-center">
							<Dialog.Title className="text-lg font-medium leading-6 text-gray-900 mb-4">
								Error!
							</Dialog.Title>
							<p className="text-sm text-gray-500 mb-6">{errorMessage}</p>
							<button
								type="button"
								onClick={() => setShowErrorMessage(false)}
								className="inline-flex justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
							>
								Okay
							</button>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>

			<Dialog
				open={showDeleteConfirmation}
				onClose={() => {
					setShowDeleteConfirmation(false);
					setLanguageToDelete(null);
				}}
				className="relative z-50"
			>
				<div className="fixed inset-0 bg-black/30" aria-hidden="true" />

				<div className="fixed inset-0 flex items-center justify-center">
					<Dialog.Panel className="mx-auto max-w-md rounded-lg bg-white p-6 shadow-xl">
						<div className="text-center">
							<Dialog.Title className="text-lg font-medium leading-6 text-gray-900 mb-4">
								Confirm Deletion
							</Dialog.Title>
							<p className="text-sm text-gray-500 mb-6">
								Are you sure you want to remove {languageToDelete?.name}?
							</p>
							<div className="flex justify-center gap-3">
								<button
									type="button"
									onClick={() => {
										setShowDeleteConfirmation(false);
										setLanguageToDelete(null);
									}}
									className="inline-flex justify-center rounded-md bg-gray-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500"
								>
									Cancel
								</button>
								<button
									type="button"
									onClick={handleDeleteLanguage}
									disabled={isLoading}
									className={`inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white shadow-sm ${
										isLoading
											? 'bg-gray-400 cursor-not-allowed'
											: 'bg-red-600 hover:bg-red-500'
									}`}
								>
									{isLoading ? 'Removing...' : 'Remove'}
								</button>
							</div>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>
		</div>
	);
}
