import { sorbunuApi } from '../axios';
import { endPoints } from '../../endPoints';

export const createOrder = async orderData => {
	const response = await sorbunuApi.post(
		endPoints.checkout.CREATE_ORDER(),
		orderData,
	);
	return response.data;
};
