export const endPoints = {
	auth: {
		SIGN_IN: () => '/auth/login/',
		LOG_OUT: () => '/auth/logout/',
	},
	projects: {
		GET_PROJECTS: (status, sorting) =>
			`/projects/?ordering=${sorting}${
				status === 'all' ? '' : `&status=${status}`
			}`,
		RETRIEVE_PROJECT: nanoid => `/projects/${nanoid}/detailed`,
		PROJECTS_MARKETING: nanoid => `/projects/${nanoid}/marketing-info/`,
		UPDATE_STATUS: nanoid => `/projects/${nanoid}/`,
		COMMENTS: nanoid => `/projects/${nanoid}/comments/`,
		GET_PROJECT_COST: nanoid => `/projects/${nanoid}/cost/`,
	},
	transactions: {
		GET_PROJECT_PURCHASE_ORDERS: nanoid =>
			`/transactions/project-purchase-orders/${nanoid}/`,
		DELETE_TRANSACTION: nanoid => `/transactions/${nanoid}/`,
	},
	responses: {
		GET_PROJECT_RESPONSES: projectNanoId =>
			`/responses/?questionnaire__project__nanoid=${projectNanoId}`,
		RETRIEVE_RESPONSE: responseNanoId => `/responses/${responseNanoId}`,
	},
	responseReviews: {
		POST_RESPONSE_REVIEW: () => `/response-reviews/`,
	},
	responseMeasurements: {
		GET_QUESTIONNAIRE_HISTOGRAM: questionnaireNanoId =>
			`/response-measurements/histogram/?response__questionnaire__nanoid=${questionnaireNanoId}`,
		GET_RESPONSE_MEASUREMENT: responseNanoId =>
			`/response-measurements/?response__nanoid=${responseNanoId}`,
	},
	questionMeasurements: {
		GET_QUESTION_HISTOGRAM: questionNanoId =>
			`/question-measurements/histogram/?question__nanoid=${questionNanoId}`,
		GET_QUESTION_MEASUREMENT: (questionNanoId, responseNanoId) =>
			`/question-measurements/?question__nanoid=${questionNanoId}&response_measurement__response__nanoid=${responseNanoId}`,
	},
	questions: {
		GET_QUESTIONNAIRE_QUESTIONS: questionnaireNanoId =>
			`/questionnaires/${questionnaireNanoId}/questions/?limit=9999`,
	},
	users: {
		GET_USERS: (sorting, limit = 10, page = 1) =>
			`/users/?is_anonymized=false&ordering=${sorting}&limit=${limit}&page=${page}`,
		CREATE_USER: () => '/users/',
		DELETE_USER: nanoid => `/users/${nanoid}/anonymize/`,
		MODIFY_USER: nanoid => `/users/${nanoid}/`,
	},
	promoCodes: {
		GET_PROMO_CODES: () => '/promo-codes/',
		CREATE_PROMO_CODE: () => '/promo-codes/',
		UPDATE_PROMO_CODE: nanoid => `/promo-codes/${nanoid}`,
	},
	dashboard: {
		GET_COMPLETED_PROJECTS_ON_TIME: () =>
			'bo-analysis/projects-completed-on-time/',
		GET_ACCOUNTS_WITH_AT_LEAST_ONE_PROJECT: () =>
			'bo-analysis/accounts-with-at-least-one-project/',
		GET_RESPONSE_TIME_SUCCESS_RATE: () =>
			'bo-analysis/response-time-success-rate/',
		GET_UNDER_APPROVAL_PROJECTS: () => 'projects/?status=under_approval',
		GET_PROJECTS_WITHOUT_ACTION: () => 'projects/get-without-action-projects/',
		GET_PROJECTS_NEARING_ESTIMATED_TIME: () =>
			'projects/get-projects-nearing-estimated-time/',
	},
	questionnaires: {
		ADD_LANGUAGE: nanoid => `/questionnaires/${nanoid}/languages/`,
		REMOVE_LANGUAGE: (nanoid, language) =>
			`/questionnaires/${nanoid}/languages/${language}`,
		TRANSLATE_PROJECT: nanoid =>
			`/questionnaires/${nanoid}/languages/translate-project/`,
	},
	addresses: {
		GET_USER_ADDRESSES: userNanoId => `/address/user/${userNanoId}/`,
	},
	checkout: {
		CREATE_ORDER: () => '/checkout/',
	},
};
