import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	addresses: [],
	loading: false,
	error: null,
};

const addressesSlice = createSlice({
	name: 'addresses',
	initialState,
	reducers: {
		setAddresses: (state, action) => {
			state.addresses = action.payload;
		},
		setAddressesLoading: (state, action) => {
			state.loading = action.payload;
		},
		setAddressesError: (state, action) => {
			state.error = action.payload;
		},
	},
});

export const { setAddresses, setAddressesLoading, setAddressesError } =
	addressesSlice.actions;

export default addressesSlice.reducer;
