import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getAllProjects({ status, sorting }) {
	const response = await sorbunuApi.get(
		endPoints.projects.GET_PROJECTS(status, sorting),
	);

	return response.data.results;
}

export async function getProject({ projectNanoId }) {
	const response = await sorbunuApi.get(
		endPoints.projects.RETRIEVE_PROJECT(projectNanoId),
	);

	return response.data;
}

export const getProjectPurchaseOrders = async projectNanoId => {
	const response = await sorbunuApi.get(
		endPoints.transactions.GET_PROJECT_PURCHASE_ORDERS(projectNanoId),
	);
	return response.data;
};

export const getProjectCost = async projectNanoId => {
	const response = await sorbunuApi.get(
		endPoints.projects.GET_PROJECT_COST(projectNanoId),
	);
	return response.data;
};

export const deleteTransaction = async transactionId => {
	const response = await sorbunuApi.delete(
		endPoints.transactions.DELETE_TRANSACTION(transactionId),
	);
	return response.data;
};
