import { all, fork } from 'redux-saga/effects';
import { authModuleSaga } from '../../api/Services/Authentication';

import { usersModuleSaga } from '../../api/Services/Users';
import { promoCodesModuleSaga } from '../../api/Services/PromoCodes';
import { dashboardModuleSaga } from '../../api/Services/Dashboard';
import { projectsModuleSaga } from './projects';
import { responsesModuleSaga } from './responses';
import { addressesModuleSaga } from './addresses';

function* rootSaga() {
	yield all([
		fork(authModuleSaga),
		fork(projectsModuleSaga),
		fork(responsesModuleSaga),
		fork(usersModuleSaga),
		fork(promoCodesModuleSaga),
		fork(dashboardModuleSaga),
		fork(addressesModuleSaga),
	]);
}

export default rootSaga;
